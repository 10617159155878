import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Hero from "./ShopHero";
import Filter from "./ShopFilter";
import "./Shop.css";
import { Product } from "./Product";
import { CartContext } from "../../Context/CartContext";
import Paginate from "./Paginate";

function Shop() {
  const shopCat = useLocation();
  const { cat } = shopCat.state || "All";

  const [defaultCategory, setDefaultCategory] = useState(
    cat == null ? "All" : cat
  );

  !sessionStorage.getItem("Page") && sessionStorage.setItem("Page", 1);

  const {
    productDispatch,
    productState: { sort, byKeyword, onSale, inStock, byCategory },
  } = useContext(CartContext);

  const getShopFilter = (value) => {
    productDispatch({
      type: "FILTER_BY_CATEGORY",
      payload: value,
    });
    setDefaultCategory(value);
    setCurrentPage(1);
    sessionStorage.setItem("Page", 1);
    productDispatch({
      type: "CLEAR_FILTERS",
    });
  };

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    productDispatch({
      type: "FILTER_BY_CATEGORY",
      payload: defaultCategory,
    });
    if (sort !== "" || onSale || byKeyword) {
      sessionStorage.setItem("Page", 1);
      setCurrentPage(Number(sessionStorage.getItem("Page")));
    }
    async function fetchProducts() {
      try {
        let response = await axios("https://hom.herokuapp.com/api/getProducts");
        let products = await response.data;
        setProducts(products);
        setIsLoading(false);
      } catch (error) {
        console.log(error.response);
      }
    }
    fetchProducts();
  }, [cat, productDispatch, defaultCategory, byKeyword, sort, onSale]);

  const transformProducts = () => {
    let productslist = products;

    if (byCategory !== "All") {
      productslist = productslist.filter(
        (prod) => prod.CategoryName === byCategory
      );
    }

    if (sort !== "") {
      productslist = productslist.sort((a, b) =>
        sort === "lowToHigh" ? a.Price - b.Price : b.Price - a.Price
      );
    }

    if (onSale) {
      productslist = productslist.filter((prod) => prod.Discount === 1 || prod.Discount33 === 1 || prod.Discount50 === 1);
    }

    if (byKeyword) {
      productslist = productslist.filter(
        (prod) =>
          prod.Keywords.toLowerCase().includes(byKeyword.toLowerCase()) ||
          prod.ProductName.toLowerCase().includes(byKeyword.toLowerCase())
      );
    }

    if (inStock) {
      productslist = productslist.filter((prod) => prod.inStock === 1);
    }
    return productslist;
  };

  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem("Page"))
  );
  const [prodPerPage] = useState(8);

  const indexOfLastProduct = currentPage * prodPerPage;
  const indexOfFirstProduct = indexOfLastProduct - prodPerPage;
  const currentProducts = transformProducts().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => {
    sessionStorage.setItem("Page", Number(pageNumber));
    setCurrentPage(pageNumber);
    window.scrollTo(0, 500);
  };

  return (
    <div className="shop">
      <Helmet>
        <title>HOM Shop</title>
        <meta
          name="description"
          content="Checkout our shop for lowest prices in the Kingdom!"
        />
        <meta property="og:title" content="Shop" />
        <meta
          name="categories"
          content="Supplements, Kids, Essential Oils, Personal Care, Natural Foods, Organic"
        />
      </Helmet>
      <Hero getShopFilter={getShopFilter} cat={defaultCategory} />
      <div className="columns">
        <div className="left-column">
          <div className="filter-bar">
            <Filter />
          </div>
        </div>
        <div className={isLoading ? ("right-column loading-products") : ("right-column")}>
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div className="product-cards">
              {currentProducts.map(
                (product, index) => (
                  <React.Fragment key={index}>
                    <Product product={product} />
                  </React.Fragment>
                ),
                []
              )}
            </div>
          )}
          <div className="Paginate">
            <Paginate
              productsPerPage={prodPerPage}
              totalProducts={transformProducts().length}
              paginate={paginate}
              pageNumber={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
