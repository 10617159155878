import React from "react";
import benefitIcon from "../../Images/Logo/benefit.png";
import qr from "../../Images/Logo/qr.webp";
import formatCurrency from "../../utilities/formatCurrency";

const Popup = ({ amount, submitOrder }) => {
  return (
    <div className="popup-box">
      <div className="box">
        <h1 className="popup-title">
          Pay by <img src={benefitIcon} className="benefit-icon" alt="icon" />
          enefit Pay
        </h1>
        <div className="benefit-qr">
          <img src={qr} alt="qr" />
        </div>
        <div className="popup-text">
          <h3>
            Pay <span>{formatCurrency(amount)}</span> to the above QR Code
          </h3>
          <p>
            Send a screenshot of the transaction through whatsapp to{" "}
            <a href="https://wa.me/0097338342672" className="whatsapp-link">
              38342672
            </a>
            .
          </p>
          <p>
            <span className="noStock-text">Note:</span> Order will not be confirmed without sending transaction proof.
          </p>
          <button onClick={submitOrder} className="btn checkout-btn">
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
