import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../Images/Logo/logo.webp";
import "./NavbarStyles.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { CartContext } from "../../Context/CartContext";
import CartPopup from "./CartPopup";

function Navbar() {
  const [show, setShow] = useState(true);
  var prevScrollpos = window.pageYOffset;

  const controlNavbar = () => {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      setShow(false);
    } else {
      setShow(true);
    }
    prevScrollpos = currentScrollPos;
    if (currentScrollPos === 0) {
      setShow(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  });

  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(!clicked);
  };
  const { getCartSize } = useContext(CartContext);

  const cartSize = getCartSize();

  return (
    <nav className={`NavbarItems ${!show && "noNav"}`}>
      <Link to={"/"}>
        <img src={logo} alt="logo" className="logo btn btn-ghost" />
      </Link>
      <div className="menu-icons" onClick={() => handleClick()}>
        {clicked ? (
          <FaTimes className="hamburger-icon" />
        ) : (
          <FaBars className="hamburger-icon" />
        )}
      </div>
      <div className="navigation-options">
        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          <Link to={"/"}>
            <li className="nav-links font-bold text-lg" onClick={() => setClicked(false)}>Home</li>
          </Link>
          {window.location.pathname === "/" ? (
            <React.Fragment>
              <a href="/#about">
                <li className="nav-links font-bold text-lg" onClick={() => setClicked(false)}>About Us</li>
              </a>
              <a href="/#contact">
                <li className="nav-links font-bold text-lg" onClick={() => setClicked(false)}>Contact Us</li>
              </a>
            </React.Fragment>
          ) : null}
          <Link to={"/Shop"} state={{ cat: "All" }}>
            <li className="nav-links font-bold text-lg" onClick={() => setClicked(false)}>Shop</li>
          </Link>
          <Link to={"/Recipes"}>
            <li className="nav-links font-bold text-lg" onClick={() => setClicked(false)}>Recipes</li>
          </Link>
        </ul>
        <div className="flex-none">
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <div className="indicator">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 cart"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                {cartSize > 0 ? (
                  <span className="badge badge-sm indicator-item">
                    {cartSize}
                  </span>
                ) : null}
              </div>
            </label>
            <CartPopup />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
