import React, { useContext } from "react";
import { Link } from "react-router-dom";
import formatCurrency from "../../utilities/formatCurrency";
import { CartContext } from "../../Context/CartContext";

function CartPopup() {
  const { getCartSize, getTotalCartAmount } = useContext(CartContext);
  const cartSize = getCartSize();
  const cartAmount = getTotalCartAmount();
  return (
    <div
      tabIndex={0}
      className="mt-3 card card-compact dropdown-content w-52 bg-base-100 shadow"
    >
      <div className="card-body">
        <span className="font-bold text-lg">{cartSize} Items</span>
        <span className="text-info">
          Subtotal: {formatCurrency(cartAmount)}
        </span>
        <div className="card-actions">
          <Link to={"/MyCart"} className="cart-link">
            <button className="btn btn-block view-cart">View cart</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CartPopup;
