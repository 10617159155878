import React, { useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import { Link } from "react-router-dom";
import formatCurrency from "../../utilities/formatCurrency";

export const Product = ({ product }) => {
  const { addToCart, cartItems, removeFromCart } = useContext(CartContext);

  const sale = product.Discount;
  const sale33 = product.Discount33;
  const sale50 = product.Discount50;

  const stock = product.inStock;

  const Item = cartItems?.find((item) => item?.Name === product?.ProductName);

  let cartItemCount = 0;

  if (Item) {
    cartItemCount = Item?.Qty;
  }

  return (
    <div
      className={
        stock === 1 ? "indicator product" : "indicator product out-of-stock"
      }
    >
      {sale ? (
        <span className="indicator-item badge badge-red">Sale</span>
      ) : sale33 ? (
        <span className="indicator-item badge badge-red">2+1</span>
      ) : sale50 ? (
        <span className="indicator-item badge badge-red">50%</span>
      ) : null}
      <Link to={`/Item/${product.idproducts}/${product.ProductName}`}>
        <figure className="px-10 pt-10">
          <img
            loading="lazy"
            src={product.ProductImage}
            alt={product.ProductName}
            className="rounded-xl"
          />
        </figure>
        <div className="card-body items-center text-center">
          <h4 className="product-title card-title">{product.ProductName}</h4>
          <p className="product-size">{product.Size}</p>
          {sale ? (
            <div className="sale">
              <p className="cross">{formatCurrency(product.Price)}</p>
              <p className="card-price">
                {formatCurrency(product.Price * 0.8)}
              </p>
            </div>
          ) : sale33 ? (
            <div className="sale">
              <p className="cross">{formatCurrency(product.Price)}</p>
              <p className="card-price">
                {formatCurrency((product.Price * 2) / 3)}
              </p>
            </div>
          ) : sale50 ? (
            <div className="sale">
              <p className="cross">{formatCurrency(product.Price)}</p>
              <p className="card-price">
                {formatCurrency(product.Price * 0.5)}
              </p>
            </div>
          ) : (
            <p className="card-price">{formatCurrency(product.Price)}</p>
          )}
        </div>
      </Link>
      {stock === 0 && <span className="noStock-text">Out of Stock!</span>}
      <div className="card-actions flexible">
        {cartItemCount > 0 ? (
          <button
            className="btn btn-primary remove-cart"
            onClick={() => removeFromCart(product.ProductName)}
            disabled={stock === 0 && true}
          >
            Remove from Cart
            {cartItemCount > 0 ? <>({cartItemCount})</> : null}
          </button>
        ) : (
          <button
            className="btn btn-primary add-cart"
            onClick={() => addToCart(product.ProductName)}
            disabled={stock === 0 && true}
          >
            Add to Cart
          </button>
        )}
      </div>
    </div>
  );
};
