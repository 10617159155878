import { useState, useEffect } from "react";
import axios from "axios";
import "./Recipes.css";
import Hero from "./RecipesHero";
import RecipeCard from "./RecipeCard";
import Paginate from "../Shop/Paginate";
import { Helmet } from "react-helmet";

function Recipes() {
  const [catFilter, setCatFilter] = useState("All");

  const getRecipeFilter = (value) => {
    setCatFilter(value);
    setCurrentPage(1);
  };

  const [recipes, setRecipes] = useState([]);
  useEffect(() => {
    async function fetchRecipes() {
      try {
        let response = await axios("https://hom.herokuapp.com/api/getRecipes");
        let r = await response.data;
        setRecipes(r);
      } catch (error) {
        console.log(error.response);
      }
    }
    fetchRecipes();
  }, []);

  const transformRecipes = () => {
    let recipeslist = recipes;

    if (catFilter !== "All") {
      recipeslist = recipeslist.filter(
        (recipe) => recipe.RecipeCategory === catFilter
      );
    }
    return recipeslist;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [prodPerPage] = useState(4);

  const indexOfLastProduct = currentPage * prodPerPage;
  const indexOfFirstProduct = indexOfLastProduct - prodPerPage;
  const currentProducts = transformRecipes().slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  return (
    <div className="recipes">
      <Helmet>
        <title>HOM Recipes</title>
        <meta
          name="description"
          content="Checkout our recipes for personal care and aromatherapy!"
        />
        <meta property="og:title" content="Recipes" />
        <meta
          name="categories"
          content="Men Grooming Recipes, Medical Recipes, Skin Care Recipes, Body Care Recipes, AntiAging Recipes"
        />
      </Helmet>
      <Hero getRecipeFilter={getRecipeFilter} />
      {currentProducts.map(
        (recipe, index) => (
          <RecipeCard key={index} recipe={recipe} />
        ),
        []
      )}
      ;
      <div className="Paginate">
        <Paginate
          productsPerPage={prodPerPage}
          totalProducts={transformRecipes().length}
          paginate={paginate}
          pageNumber={currentPage}
        />
      </div>
    </div>
  );
}

export default Recipes;
