import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navigation/Navbar";
import Home from "./Components/Home/Home";
import Shop from "./Components/Shop/Shop";
import Recipe from "./Components/Recipes/Recipes";
import Cart from "./Components/Cart/Cart";
import Item from "./Components/Item/Item";
import NotFound from "./Components/NotFound/NotFound";
import Footer from "./Components/Footer/Footer";
import ReactGA from "react-ga";
import { CartContextProvider } from "./Context/CartContext";

const tracking_id = "G-Y01GTETDB7";
ReactGA.initialize(tracking_id);

function App() {
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  if (isLoading) {
    return null;
  }

  return (
    <div className="App">
      <CartContextProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Shop" element={<Shop />} />
            <Route exact path="/Recipes" element={<Recipe />} />
            <Route exact path="/MyCart" element={<Cart />} />
            <Route exact path="/Item/:id/:name" element={<Item />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </CartContextProvider>
    </div>
  );
}

export default App;
