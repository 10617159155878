import { useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import { RiArrowDropDownLine } from "react-icons/ri";

function ShopFilter() {
  const {
    productState: { sort, onSale, byKeyword, inStock },
    productDispatch,
  } = useContext(CartContext);

  return (
    <div className="filter__container">
      <div className="filter__row">
        <div className="filter__item">
          <input
            id="keyword"
            className="keybig"
            type="checkbox"
            defaultChecked
          />
          <label htmlFor="keyword" className="namelabel">
            keyword
            <RiArrowDropDownLine />
          </label>
          <div className="searchContainer">
            <input
              type="text"
              className="keyword__search"
              placeholder="Try Aromatherapy, Women, Organic"
              onChange={(e) =>
                productDispatch({
                  type: "FILTER_BY_KEYWORD",
                  payload: e.target.value,
                })
              }
            />
          </div>
          {byKeyword ? (
            <div className="filter_text">
              <span>Results for "{byKeyword}" is shown</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="filter__row">
        <div className="filter__item">
          <input id="price" className="keybig" type="checkbox" defaultChecked />
          <label htmlFor="price" className="namelabel">
            Price
            <RiArrowDropDownLine />
          </label>
          <div className="searchContainer price__container">
            <div>
              <label className="cursor-pointer label">
                <span className="label-text">Low to High</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-warning"
                  onChange={() =>
                    productDispatch({
                      type: "SORT_BY_PRICE",
                      payload: "lowToHigh",
                    })
                  }
                  checked={sort === "lowToHigh" ? true : false}
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text">High to Low</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-warning"
                  onChange={() =>
                    productDispatch({
                      type: "SORT_BY_PRICE",
                      payload: "highToLow",
                    })
                  }
                  checked={sort === "highToLow" ? true : false}
                />
              </label>
            </div>
            <div>
              <label className="cursor-pointer label">
                <span className="label-text">On Sale</span>
                <input
                  type="checkbox"
                  className="checkbox checkbox-warning"
                  onChange={() =>
                    productDispatch({
                      type: "FILTER_ON_SALE",
                    })
                  }
                  checked={onSale}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="filter__row">
        <label className="cursor-pointer label">
          <span className="label-text">In Stock</span>
          <input
            type="checkbox"
            className="checkbox checkbox-warning"
            onChange={() =>
              productDispatch({
                type: "FILTER_IN_STOCK",
              })
            }
            checked={inStock}
          />
        </label>
      </div>
        <div className="filter__row">
          <button
            className="btn clear-filter"
            onClick={() =>
              productDispatch({
                type: "CLEAR_FILTERS",
              })
            }
          >
            Clear Filters
          </button>
        </div>
    </div>
  );
}

export default ShopFilter;
