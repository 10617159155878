export const productReducer = (state, action) => {
  switch (action.type) {
    case "SORT_BY_PRICE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_KEYWORD":
      return { ...state, byKeyword: action.payload };
    case "FILTER_BY_CATEGORY":
      return { ...state, byCategory: action.payload };
    case "FILTER_ON_SALE":
      return { ...state, onSale: !state.onSale };
    case "FILTER_IN_STOCK":
      return { ...state, inStock: !state.inStock };
    case "CLEAR_FILTERS":
      return {
        byKeyword: "",
        byCategory: "All",
        sort: "",
        inStock: false,
        onSale: false,
      };
    default:
      return state;
  }
};
