import { Component } from "react";
import logo from "../../Images/Logo/logo.webp";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import "./footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer items-center p-4 text-neutral-content">
        <div className="items-center grid-flow-col">
          <img src={logo} alt="logo" className="logo-footer"  loading="lazy"/>
          <p className="footer-text"> Your Gate to a Healthy Life</p>
        </div>
        <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/houseofmedicine.bh/"
            className="social-icons"
          >
            <BsFacebook className="social-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/houseofmedicine.bh/?hl=en"
            className="social-icons"
          >
            <AiFillInstagram className="social-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCNIXV93QBeYR-GTmPZogrgQ"
            className="social-icons"
          >
            <BsYoutube className="social-icon" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/houseofmedicinebh?originalSubdomain=bh"
            className="social-icons"
          >
            <AiFillLinkedin className="social-icon" />
          </a>
        </div>
      </footer>
    );
  }
}
export default Footer;
