import React, { useState, useEffect } from "react";
import axios from "axios";

function About() {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    axios.get("https://hom.herokuapp.com/api/getBrands").then((response) => {
      setBrands(response.data);
    });
  }, []);
  return (
    <div className="about-us">
      <div id="about" className="title">
        <h2>About House of Medicine</h2>
      </div>
      <div className="company-obj">
        <p>
          We are House of Medicine a medical distribution company established in
          Bahrain working in the field of nutrition supplements, medical
          devices, and cosmetics products. We are supplying goods to retail
          pharmacies, health retail shops, doctors' clinics, and hospitals in
          both public and private sectors, supporting this with a big
          relationship network and powerful regulatory affair department built
          over a strong logistical platform.
        </p>
      </div>

      <div className="company-quotes">
        <div className="mission">
          <h3>OUR MISSION</h3>
          <p>
            To provide the best healthcare products and equipment through
            introducing the biggest brand names to the market, bring the most
            recent and advanced technologies, and the highest quality products
            and supplements in order to maximize the medical benefits to our
            customers, and enriching the community awareness through patient
            education programs.
          </p>
        </div>
        <div className="vision">
          <h3>OUR VISION</h3>
          <p>
            To support everyone in our community to enjoy a strong and healthy
            life among their families, and to be their choice for the gate to a
            healthy life.
          </p>
        </div>
      </div>
      <div className="brands">
        <h3>OUR BRANDS</h3>
        <div className="brand-cards">
          {brands.map(
            (brand, index) => (
              <div className="brand-card" key={index}>
                <a href={brand.BrandURL} target="_blank" rel="noreferrer">
                  <img src={brand.BrandLogo} alt={brand.BrandName} loading="lazy"/>
                </a>
              </div>
            ),
            []
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
