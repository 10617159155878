import React, { useState, useEffect } from "react";
import axios from "axios";
import { Product } from "../Shop/Product";
import { TbDiscount } from "react-icons/tb";
import { MdLocalOffer } from "react-icons/md";

function Featured() {
  const [saleProducts, setSaleProducts] = useState([]);
  useEffect(() => {
    axios
      .get("https://hom.herokuapp.com/api/getSaleProducts")
      .then((response) => {
        setSaleProducts(response.data);
      })
      .catch((error) => console.log(error.response.data.error));
  }, []);
  return (
    <div className="featured">
      <div className="title">
        <h2>Featured Products</h2>
      </div>
      <div className="product-cards">
        {saleProducts.filter((prod) => prod.Discount === 1).map(
          (product, index) => (
            <React.Fragment key={index}>
              <Product product={product} />
            </React.Fragment>
          ),
          []
        )}
      </div>
      <div className="offer1">
        <TbDiscount />
        <h3>Special Offers</h3>
      </div>
      <div className="product-cards">
        {saleProducts.filter((prod) => prod.Discount50 === 1).map(
          (product, index) => (
            <React.Fragment key={index}>
              <Product product={product} />
            </React.Fragment>
          ),
          []
        )}
      </div>
      <div className="offer2">
        <MdLocalOffer />
        <h3>Buy 2 Get 1 Free</h3>
      </div>
      <div className="product-cards">
        {saleProducts.filter((prod) => prod.Discount33 === 1).map(
          (product, index) => (
            <React.Fragment key={index}>
              <Product product={product} />
            </React.Fragment>
          ),
          []
        )}
      </div>
    </div>
  );
}

export default Featured;
