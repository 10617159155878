import { Component } from "react";
import "./home.css";
import Caroussel from "./Caroussel";
import Hero from "./Hero";
import Shop from "./ShopCat";
import About from "./About";
import Contact from "./Contact";
import Featured from "./Featured";
import { Helmet } from "react-helmet";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Helmet>
          <title>House of Medicine</title>
          <meta name="description" content="Your Gate to a Healthy Life" />
          <meta property="og:title" content="House of Medicine" />
          {/* <!-- Google Tag Manager --> */}
          <script>
            {(function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "GTM-5HVRMG9")}
          </script>
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>
        <Hero
          title={"Your Gate to a Healthy Life"}
          subtitle={"Checkout our Personal Care Natural Recipes!"}
          heroClass={"Home"}
        />
        <Shop />
        <Featured />
        <Caroussel type="recipes" />
        <About />
        <Caroussel type="instagram" />
        <Contact />
      </div>
    );
  }
}

export default Home;
