import React, { useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import { Categories } from "../../data/Categories";
import Hero from "../Home/Hero";

function ShopHero({ getShopFilter, cat }) {
  const {
    productDispatch,
    productState: { byCategory },
  } = useContext(CartContext);

  function handleShopFilter(value) {
    productDispatch({
      type: "FILTER_BY_CATEGORY",
      payload: value,
    });
    getShopFilter(value);
  }
  return (
    <div>
      <Hero title={"Shop"} subtitle={"Free Delivery anywhere in Bahrain!"} heroClass={"Shop"} />
      <div className="filter-tab-shop">
        <ul>
          {Categories.sort((a, b) => (a.catName > b.catName ? 1 : -1)).map(
            (category, index) => (
              <li
                className={
                  byCategory === category.short
                    ? "filter-shop active"
                    : "filter-shop"
                }
                onClick={() => handleShopFilter(category.short)}
                key={index}
              >
                {category.catName}
              </li>
            ),
            []
          )}
        </ul>
      </div>
    </div>
  );
}
export default ShopHero;
