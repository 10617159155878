export const RecipeCategories = [
    {
        catName: "All",
        catShort: "All",
    },
    {
        catName: "Anti-Aging",
        catShort: "AntiAging",
    },
    {
        catName: "Body Care",
        catShort: "BodyCare",
    },
    {
        catName: "Facial Care",
        catShort: "FacialCare",
    },
    {
        catName: "Men Grooming",
        catShort: "Men",
    },
    {
        catName: "Medical",
        catShort: "Medical",
    },

]