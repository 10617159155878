import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./item.css";
import { BiMinus } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";
import formatCurrency from "../../utilities/formatCurrency";
import { CartContext } from "../../Context/CartContext";
import { Helmet } from "react-helmet";
import Suggested from "./Suggested";

function Item() {
  const productID = useParams();
  const [product, setProduct] = useState([]);
  const [productDesc, setProductDesc] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { updateCartItemCount, cartItems } = useContext(CartContext);
  const [brand, setBrand] = useState([]);

  useEffect(() => {
    const source = axios.CancelToken.source(); // For cancelling axios calls
    
    const getProduct = async () => {
      try {
        const response = await axios.post(
          "https://hom.herokuapp.com/api/getProduct",
          { productID: productID.id },
          { cancelToken: source.token }
        );
        setProduct(response.data[0]);
      } catch (error) {
        console.log(error?.response?.data?.error);
      }
    };

    const getDesc = async () => {
      try {
        const response = await axios.post(
          "https://hom.herokuapp.com/api/getDesc",
          { productID: productID.id },
          { cancelToken: source.token }
        );
        setProductDesc(response?.data[0]);
        setIsLoading(false);
      } catch (error) {
        console.log(error?.response?.data?.error);
      }
    };
    
    getProduct();
    getDesc();
    
    return () => {
      source.cancel("Cancelling in cleanup"); // Cleanup axios calls
    };
  }, [productID]);

  useEffect(() => {
    // Only run this effect if product.idBrands is defined
    if (!product?.idBrands) return;
    
    const getBrands = async () => {
      try {
        const response = await axios.get("https://hom.herokuapp.com/api/getBrands");
        let brand = response?.data.filter(
          (brand) => brand?.idBrands === product.idBrands
        )[0]?.BrandName;
        setBrand(brand);
      } catch (error) {
        console.log(error?.response?.data?.error);
      }
    };
    
    getBrands();
  }, [product]);

  useEffect(() => {
    // Only run this effect if isLoading is false
    if (isLoading) return;
    
    const item = cartItems?.find((item) => item.Name === productID.name);
    setCartItemCount(item ? item.Qty : 0);
  }, [isLoading, cartItems, productID]);
  const stock = product.inStock;

  const sale = product.Discount;
  const sale33 = product.Discount33;
  const sale50 = product.Discount50;

  const [Qty, setQty] = useState(1);
  const price = Qty * product.Price;

  const setDecrease = () => {
    Qty > 1 ? setQty(Qty - 1) : setQty(1);
  };
  const setIncrease = () => {
    setQty(Qty + 1);
  };
  return (
    <div className="item">
      <Helmet>
      <title>{product?.ProductName || 'Product'}</title>
      <meta name="description" content={productDesc?.productDescription || ''} />
      <meta property="og:title" content={product?.ProductName || 'Product'} />
      <meta name="tagline" content={productDesc?.productTagline || ''} />
    </Helmet>
      <div className="item-display">
        {isLoading ? (
          <div className="loader-item"></div>
        ) : (
          <React.Fragment>
            <div className="item-display-name">
              <h1>{product.ProductName}</h1>
            </div>
            <div className="item-left-side">
              <div className="item-image">
                <img src={product.ProductImage} alt="product" loading="lazy" />
              </div>
            </div>
            <div className="item-right-side">
              <div className="item-top">
                <div className="item-tagline">{productDesc.productTagline}</div>
                <div className="item-size">{product.Size}</div>
              </div>
              <div className="item-description">
                <h3>Description</h3>
                <p>{productDesc.productDescription}</p>
              </div>
              <div className="item-usage">
                <h3>Suggested Usage</h3>
                <p>{productDesc.productUsage}</p>
              </div>
              <div className="item-info">
                <div className="item-brand">
                  <span>Brand: </span>
                  <p>{brand}</p>
                </div>
                <div className="item-cat">
                  <span>Category: </span>
                  <p>{product.CategoryName}</p>
                </div>
              </div>
              <div className="item-price">
                <div className="quantity-control">
                  <button className="quantity-btn" onClick={setDecrease}>
                    <BiMinus />
                  </button>
                  <input
                    type="number"
                    inputMode="numeric"
                    className="quantity-input"
                    value={Qty}
                    min="1"
                    max=""
                    name="quantity"
                    onChange={(e) => setQty(e.target.value)}
                    disabled
                  />
                  <button className="quantity-btn" onClick={setIncrease}>
                    <BsPlusLg />
                  </button>
                </div>
                {sale === 1 ? (
                  <p className="card-price">
                    <span className="cross">{formatCurrency(price)}</span>
                    {formatCurrency(price * 0.8)}
                  </p>
                ) : sale33 === 1 ? (
                  <p className="card-price">
                    <span className="cross">{formatCurrency(price)}</span>
                    {formatCurrency((price * 2) / 3)}
                  </p>
                ) : sale50 === 1 ? (
                  <p className="card-price">
                    <span className="cross">{formatCurrency(price)}</span>
                    {formatCurrency(price * 0.5)}
                  </p>
                ) : (
                  <p className="card-price">{formatCurrency(price)}</p>
                )}
                <button
                  className="btn btn-primary add-cart"
                  onClick={() => updateCartItemCount(Qty, product.ProductName)}
                  disabled={stock === 0 && true}
                >
                  {stock === 0 ? (
                    <span className="noStock-text">Out of Stock!</span>
                  ) : (
                    "Add to Cart"
                  )}
                  {cartItemCount > 0 ? (
                    <span className="in-cart">({cartItemCount}) in Cart</span>
                  ) : null}{" "}
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      <Suggested cat={product.CategoryName} />
    </div>
  );
}

export default Item;
