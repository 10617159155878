import React from "react";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { GiApothecary } from "react-icons/gi";

function Caroussel({ type }) {
  const images = (type) => {
    return (
      <div className="containerc">
        <div className="carouselc">
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
          <div className={type === "instagram" ? "imagec" : "imager"}>
            <span></span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="image-caroussel">
      <div className="caroussel-title">
        <span>
          {type === "instagram" ? <AiFillInstagram /> : <GiApothecary />}
        </span>
        {type === "instagram" ? (
          <h3 className="insta">Instagram Posts</h3>
        ) : (
          <h3 className="recipes-c">Our Recipes</h3>
        )}
      </div>
      {type === "instagram" ? (
        <a
          href="https://www.instagram.com/houseofmedicine.bh/"
          target="_blank"
          rel="noreferrer"
        >
          {images(type)}
        </a>
      ) : (
        <Link to={"/Recipes"}>{images(type)}</Link>
      )}
    </div>
  );
}

export default Caroussel;
