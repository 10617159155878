import React from "react";
import { Link } from "react-router-dom";

function Hero({ title, subtitle, heroClass }) {
  return (
    <div
      className={
        heroClass === "Home"
          ? "hero home-hero"
          : heroClass === "Shop"
          ? "hero shop-hero"
          : "hero recipe-hero"
      }
    >
      <div className="hero-overlay bg-opacity-60"></div>
      <div
        className={
          heroClass === "Recipe"
            ? "recipe-hero-content text-center text-neutral-content"
            : "hero-content text-center text-neutral-content"
        }
      >
        <div
          className={heroClass === "Recipe" ? "recipe-hero-box" : "hero-box"}
        >
          <h1 className="mb-5 text-6xl font-bold hero-title">{title}</h1>
          <p className="mb-5">{subtitle}</p>
          {heroClass === "Home" ? (
            <Link to={"/Recipes"} state={{ cat: "All" }}>
              <button className="btn btn-lg action-btn">View Recipes</button>
            </Link>
          ) : heroClass === "Recipe" ? (
            <a href="./catalog.pdf" download="Recipe Catalog.pdf">
              <button type="submit" className="btn action-btn">
                Download Recipes Catalogue
              </button>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Hero;
