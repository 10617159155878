import { createContext, useState, useReducer } from "react";
import axios from "axios";
import { productReducer } from "./Reducers";

export const CartContext = createContext(null);

const getDefaultCart = () => {
  let cart = [];
  if (localStorage.getItem("mycart")) {
    cart = JSON.parse(localStorage.getItem("mycart"));
  } else {
    try {
      async function fetchList() {
        let response = await axios(
          "https://hom.herokuapp.com/api/getProductForCart"
        );
        let response1 = await axios(
          "https://hom.herokuapp.com/api/getRecipeForCart"
        );
        let products = await response.data;
        let recipes = await response1.data;
        let list1 = products;
        let list2 = recipes;
        for (let i = 0; i < list1.length; i++) {
          cart[i] = {
            id: i + 1,
            Name: list1[i]["ProductName"],
            Price: list1[i]["Price"],
            Image: list1[i]["ProductImage"],
            Sale: list1[i]["Discount"],
            Sale33: list1[i]["Discount33"],
            Sale50: list1[i]["Discount50"],
            Qty: 0,
          };
        }
        for (let i = list1.length; i < list1.length + list2.length; i++) {
          cart[i] = {
            id: i + 1,
            Name: list2[i - list1.length]["RecipeName"],
            Price: list2[i - list1.length]["RecipePrice"],
            Image: list2[i - list1.length]["RecipeImage"],
            Sale: 0,
            Sale33: 0,
            Sale50: 0,
            Qty: 0,
          };
        }
      }
      fetchList();
    } catch (error) {
      console.log(error.response);
    }
  }
  return cart;
};

export const CartContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item].Qty > 0) {
        cartItems[item].Sale === 1
          ? (totalAmount += cartItems[item].Qty * cartItems[item].Price * 0.8)
          : cartItems[item].Sale33 === 1
          ? (totalAmount +=
              (cartItems[item].Qty * cartItems[item].Price * 2) / 3)
          : cartItems[item].Sale50 === 1
          ? (totalAmount += cartItems[item].Qty * cartItems[item].Price * 0.5)
          : (totalAmount += cartItems[item].Qty * cartItems[item].Price);
      }
    }
    return totalAmount;
  };

  const getCartSize = () => {
    let totalSize = 0;
    for (const item in cartItems) {
      if (cartItems[item].Qty > 0) {
        totalSize++;
      }
    }
    return totalSize;
  };

  const addToCart = (name) => {
    let updatedCart = cartItems.map((item) => {
      if (item?.Name === name) {
        return { ...item, Qty: 1 };
      }
      return item;
    });
    localStorage.setItem("mycart", JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const removeFromCart = (name) => {
    let updatedCart = cartItems.map((item) => {
      if (item?.Name === name) {
        return { ...item, Qty: 0 };
      }
      return item;
    });
    setCartItems(updatedCart);
    if (getCartSize() === 0) {
      localStorage.removeItem("mycart");
    } else {
      localStorage.setItem("mycart", JSON.stringify(updatedCart));
    }
  };

  const updateCartItemCount = (newAmount, name) => {
    let updatedCart = cartItems.map((item) => {
      if (item?.Name === name) {
        return { ...item, Qty: newAmount };
      }
      return item;
    });
    localStorage.setItem("mycart", JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const checkout = () => {
    localStorage.removeItem("mycart");
    setCartItems(getDefaultCart());
  };

  const [productState, productDispatch] = useReducer(productReducer, {
    byKeyword: "",
    byCategory: "All",
    sort: "",
    onSale: false,
    inStock: false,
  });

  const contextValue = {
    cartItems,
    addToCart,
    updateCartItemCount,
    removeFromCart,
    getTotalCartAmount,
    getCartSize,
    checkout,
    productState,
    productDispatch,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};
