import React from 'react';
import {BiFirstPage, BiLastPage} from "react-icons/bi";

const Paginate = ({ productsPerPage, totalProducts, paginate, pageNumber }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePrev = () => {
    if (pageNumber > 1) 
        paginate(pageNumber - 1 )
  }
  const handleNext = () => {
    if (pageNumber < pageNumbers.length) 
        paginate(pageNumber + 1)
  }

  return (
    <nav>
      <ul className='pagination'>
        <li onClick={handlePrev} className="prev"><span><BiFirstPage /></span></li>
        {pageNumbers.map(number => (
          <li key={number} className={pageNumber === number ? "num active" : "num"} onClick={() => paginate(number)}>
            <span>
              {number}
            </span>
          </li>
        ))}
        <li onClick={handleNext} className="next"><span><BiLastPage /></span></li>
      </ul>
    </nav>
  );
};

export default Paginate;