import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Categories } from "../../data/Categories";

class ShopCat extends Component {
  render() {
    return (
      <div className="shop-by-category">
        <div className="title first">
          <h2>Shop by Category</h2>
        </div>
        <div className="cat-cards">
          {Categories.map(
            (category, index) => (
              <React.Fragment key={index}>
                <Link to={"/Shop"} state={{ cat: category.short }}>
                  <div className="cat-card">
                    <div className="cat-image">
                      <img src={category.catImg} alt="supp" loading="lazy" />
                    </div>
                    <div className="cat-name">
                      <p>
                        {category.catName === "All"
                          ? "Shop All"
                          : category.catName}
                      </p>
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            ),
            []
          )}
        </div>
      </div>
    );
  }
}
export default ShopCat;
