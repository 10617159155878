import React, { useState, useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import { FaTrashAlt } from "react-icons/fa";
import formatCurrency from "../../utilities/formatCurrency";

function CartItem({ item }) {
  const [Qty, setQty] = useState(item.Qty);
  const price = Qty * item.Price;

  const { updateCartItemCount, removeFromCart } = useContext(CartContext);

  const qtyHandler = (qty) => {
    var quantity = parseInt(qty);
    if (isNaN(quantity) || quantity <= 0) {
      quantity = 1;
      return;
    }
    setQty(quantity);
    updateCartItemCount(quantity, item.Name);
  };

  return (
    <React.Fragment>
      <div className="item-img">
        <img src={item.Image} alt="product" loading="lazy" />
      </div>
      <div className="item-info">
        <div className="item-name">
          <h3>{item.Name}</h3>
        </div>
        <div className="item-qty-price">
          <div className="item-qty">
            <input
              type="number"
              inputMode="numeric"
              name="qty"
              min="1"
              max=""
              value={Qty}
              pattern="^[0-9]*$"
              onChange={(e) => qtyHandler(e.target.value)}
            />
          </div>

          <div className="item-price-c">
            <p>x {formatCurrency(item.Price)}</p>
          </div>
        </div>
        <div className="item-total-remove">
          <div className="item-total">
            Item total: <span>{formatCurrency(price)}</span> <p className="error-message">{item.Offer !== "No Offer" && item.Offer}</p>
          </div>
          <div
            className="item-remove"
            onClick={() => removeFromCart(item.Name)}
          >
            <FaTrashAlt />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CartItem;
