import React, { useContext, useState } from "react";
import { CartContext } from "../../Context/CartContext";
import formatCurrency from "../../utilities/formatCurrency";
import BillingForm from "./BillingForm";

function Checkout({ cart }) {
  const [payment, setPayment] = useState("benefit");
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [codeValid, setCodeValid] = useState(null);

  const validatePromoCode = async () => {
    try {
      const response = await fetch(
        "https://hom.herokuapp.com/api/validateCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: promoCode }),
        }
      );

      const data = await response.json();
      if (data.valid || promoCode === "PALESTINE") {
        setDiscount(totalAmount * 0.2);
        setCodeValid(true);
      } else {
        setDiscount(0);
        setCodeValid(false);
      }
    } catch (error) {
      console.error("Error validating promo code:", error);
    }
  };

  const handlePayment = () => {
    payment === "benefit" ? setPayment("cash") : setPayment("benefit");
  };

  const { getTotalCartAmount } = useContext(CartContext);
  let totalAmount = getTotalCartAmount();

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setCodeValid(null);
  };

  totalAmount = totalAmount - discount;
  const vatAmount = totalAmount * 1.1;

  return (
    <div className="checkout">
      <div className="heading">
        <h2>Checkout</h2>
      </div>
      <div className="checkout-subtotal">
        Subtotal:
        <span>{formatCurrency(totalAmount)}</span>
      </div>
      <div className="checkout-tax">
        VAT (10%):
        <span>{formatCurrency(totalAmount * 0.1)}</span>
      </div>
      <div className="checkout-total">
        Total:
        <span>{formatCurrency(vatAmount)}</span>
      </div>
      <div className="promo-code-section">
        <input
          type="text"
          placeholder="Enter Promo Code"
          value={promoCode}
          onChange={handlePromoCodeChange}
          className={
            codeValid !== null ? (codeValid ? "valid" : "invalid") : ""
          }
        />
        <button className="btn checkout-btn" onClick={validatePromoCode}>
          Apply
        </button>
      </div>
      {codeValid === true && (
        <span className="discount-message">20% discount applied!</span>
      )}
      {codeValid === false && (
        <span className="error-message">Invalid promo code!</span>
      )}
      {codeValid && (
        <div className="checkout-discount">
          Discount:
          <span>-{formatCurrency(discount)}</span>
        </div>
      )}

      <div className="payment-wrapper">
        <input
          type="radio"
          name="select"
          id="option-1"
          value="benefit"
          checked={payment === "benefit"}
          onClick={() => handlePayment()}
          readOnly
        />
        <input
          type="radio"
          name="select"
          id="option-2"
          value="cash"
          checked={payment === "cash"}
          onClick={() => handlePayment()}
          readOnly
        />
        <label htmlFor="option-1" className="option option-1">
          <div className="dot"></div>
          <span>Benefit Pay</span>
        </label>
        <label htmlFor="option-2" className="option option-2">
          <div className="dot"></div>
          <span>Cash/Credit on Delivery</span>
        </label>
      </div>
      <BillingForm
        payment={payment}
        amount={vatAmount}
        cart={cart}
        promoCode={promoCode}
      />
    </div>
  );
}

export default Checkout;
