import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import Popup from "./Popup";

function BillingForm({ payment, amount, cart, promoCode }) {
  const { checkout } = useContext(CartContext);
  const [status, setStatus] = useState("Checkout");
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();

  const [nameValid, setNameValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [addressValid, setAddressValid] = useState(true);

  const handleNameChange = (e) => {
    setNameValid(e.target.value.trim().length > 0);
  };

  const handlePhoneChange = (e) => {
    setPhoneValid(
      e.target.value.trim().length >= 8 &&
        /^[0-9]*$/.test(e.target.value.trim())
    );
  };

  const handleAddressChange = (e) => {
    setAddressValid(e.target.value.trim().length > 0);
  };

  const handleNameBlur = (e) => {
    setNameValid(e.target.value.trim().length > 0);
  };

  const handlePhoneBlur = (e) => {
    setPhoneValid(
      e.target.value.trim().length >= 8 &&
        /^[0-9]*$/.test(e.target.value.trim())
    );
  };

  const handleAddressBlur = (e) => {
    setAddressValid(e.target.value.trim().length > 0);
  };

  const togglePopup = () => {
    setPopup(!popup);
  };

  const markPromoCodeAsUsed = async () => {
    try {
      const response = await fetch(
        "https://hom.herokuapp.com/api/setCodeUsed",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({ code: promoCode }),
        }
      );

      const result = await response.json();
      if (response.status === 200) {
        console.log(result.message); // Logs "Updated successfully"
      } else {
        console.error("Error marking promo code as used:", result.message);
      }
    } catch (error) {
      console.error("Error marking promo code as used:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Placing order...");
    const { name, phone, address } = e.target.elements;
    let details = {
      name: name.value,
      phone: phone.value,
      message: address.value,
      order: JSON.stringify(cart),
      payment: payment,
      total: amount,
      promoCode: promoCode, // passing the promo code
    };
    let response = await fetch("https://hom.herokuapp.com/sendOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Order Confirmed!");

    if (payment === "benefit") {
      togglePopup();
    } else {
      let result = await response.json();
      alert(result.status);
      submitOrder();
    }
  };

  const submitOrder = async () => {
    if (promoCode && promoCode !== "PALESTINE") {
      await markPromoCodeAsUsed();
    }
    checkout();
    navigate("/");
  };

  return (
    <div className="billing">
      <div className="heading">
        <h3>Billing Information</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <input
            type="text"
            name="name"
            id="name"
            required
            className={!nameValid ? "invalid" : ""}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
          />
          <label>Name</label>
        </div>
        <div className="form-item">
          <input
            type="text"
            name="phone"
            id="phone"
            minLength="8"
            pattern="^[0-9]*$"
            required
            className={!phoneValid ? "invalid" : ""}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
          />
          <label>Phone</label>
        </div>
        <div className="form-item">
          <textarea
            name="address"
            id="address"
            required
            className={!addressValid ? "invalid" : ""}
            onChange={handleAddressChange}
            onBlur={handleAddressBlur}
          ></textarea>
          <label>Address</label>
        </div>
        <button className="btn checkout-btn" type="submit">
          {status}
        </button>
      </form>
      {popup && <Popup amount={amount} submitOrder={submitOrder} />}
    </div>
  );
}

export default BillingForm;
