import { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./NotFound.css";

class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <Helmet>
          <title>Page not found</title>
        </Helmet>
        <div className="not-found-card">
          <div className="not-found-container">
            <div className="error-1">
              <h1 className="not-found-title">4</h1>
            </div>
            <div className="error-wheel">
              <div className="circle">
                <div className="circle2"></div>
              </div>
            </div>
            <div className="error-2">
              <h1 className="not-found-title h">4</h1>
            </div>
          </div>
          <div className="error-message">
            <p className="error-3">Page not found</p>
            <button className="btn btn-lg action-btn">
              <Link to={"/"}>Go to Home</Link>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default NotFound;
