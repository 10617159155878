import React, { useState, useEffect } from "react";
import axios from "axios";
import "./item.css";
import { Product } from "../Shop/Product";

function Suggested({ cat }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        let response = await axios("https://hom.herokuapp.com/api/getProducts");
        let products = await response.data.filter(
          (prod) => prod.CategoryName === cat
        );
        const shuffled = products.sort(() => Math.random() - 0.5);
        setProducts(shuffled);
      } catch (error) {
        console.log(error.response);
      }
    }
    fetchProducts();
  }, [cat]);
  return (
    <div className="similar-items">
      <h2>Suggested Products</h2>
        <div className="product-cards">
          {products.slice(0,4).map(
            (product, index) => (
              <React.Fragment key={index}>
                <Product product={product} />
              </React.Fragment>
            ),
            []
          )}
        </div>
    </div>
  );
}

export default Suggested;
