import React, { useContext } from "react";
import { CartContext } from "../../Context/CartContext";
import formatCurrency from "../../utilities/formatCurrency";

function RecipeCard({ recipe }) {
  const { addToCart, cartItems, removeFromCart } = useContext(CartContext);

  const Item = cartItems.filter((item) => item.Name === recipe.RecipeName);

  const cartItemCount = Item[0]?.Qty;

  return (
    <div className="card-recipe">
      <div className="card-publication">
        <img src={recipe.RecipeImage} alt="" loading="lazy" />
      </div>
      <div className="card-info">
        <h2 className="recipe-title">{recipe.RecipeName}</h2>
        <span className="recipe-subtitle">{recipe.RecipeDescription}</span>
        <div className="recipe-description">
          <div className="recipe-ingredients">
            <h3>Ingredients</h3>
            <p>{recipe.RecipeIngredients}</p>
          </div>
          <div className="recipe-instructions">
            <h3>Instructions</h3>
            <p>{recipe.RecipeInstructions}</p>
          </div>
        </div>
        <div className="recipe-price">
          <span className="cross">
            {formatCurrency(recipe.RecipePrice * 1.2)}
          </span>
          <p>{formatCurrency(recipe.RecipePrice)}</p>
          {cartItemCount > 0 ? (
            <button
              className="btn btn-primary remove-cart"
              onClick={() => removeFromCart(recipe.RecipeName)}
            >
              Remove from Cart
              {cartItemCount > 0 ? <>({cartItemCount})</> : null}
            </button>
          ) : (
            <button
              className="btn btn-primary add-cart"
              onClick={() => addToCart(recipe.RecipeName)}
            >
              Add to Cart
              {/* {cartItemCount > 0 ? <>({cartItemCount})</> : null} */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default RecipeCard;
