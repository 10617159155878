import supp from "../Images/Category/supplements.webp";
import beauty from "../Images/Category/beauty.webp";
import oils from "../Images/Category/oils.webp";
import foods from "../Images/Category/foods.webp";
import kids from "../Images/Category/kids.webp";
import all from "../Images/Category/all.webp";

export const Categories = [
    {
        catName: "Supplements",
        catImg: supp,
        short: "Supplements",
    },
    {
        catName: "Beauty & Health",
        catImg: beauty,
        short: "Health&Beauty",
    },
    {
        catName: "Essential Oils",
        catImg: oils,
        short: "EssentialOils",
    },
    {
        catName: "Kids",
        catImg: kids,
        short: "Kids",
    },
    {
        catName: "Natural Foods",
        catImg: foods,
        short: "Foods",
    },
    {
        catName: "All",
        catImg: all,
        short: "All",
    },
]