import React, { useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";

function Contact() {
  const [status, setStatus] = useState("Submit");
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleNameChange = (e) => {
    setNameValid(e.target.value.trim().length > 0);
  };

  const handleEmailChange = (e) => {
    setEmailValid(validateEmail(e.target.value.trim()));
  };

  const handleNameBlur = (e) => {
    setNameValid(e.target.value.trim().length > 0);
  };

  const handleEmailBlur = (e) => {
    setEmailValid(validateEmail(e.target.value.trim()));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://hom.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Email Sent");
    let result = await response.json();
    alert(result.status);
    setStatus("Send Again");
  };
  return (
    <div id="contact">
      <div className="title">
        <h2>Contact Us</h2>
      </div>
      <div className="contact">
        <div className="contact-box">
          <div className="get-in-touch">
            <h3 className="get-in-touch-title">Get in Touch</h3>
            <div className="contact-links">
              <div className="contact-link">
                <div className="contact-icons">
                  <BsFillTelephoneFill />
                </div>
                <a href="tel:+97338342672"> +973 383 42 672</a>
              </div>
              <div className="contact-link">
                <div className="contact-icons">
                  <GrMail />
                </div>
                <a href="mailto:info@houseofmedicine.bh">
                  {" "}
                  info@houseofmedicine.bh
                </a>
              </div>
            </div>
          </div>
          <div className="contact-form-wrapper">
            <h3>Send us a Message: </h3>
            <form onSubmit={handleSubmit}>
              <div className="form-item">
                <input
                  type="text"
                  name="sender"
                  id="name"
                  required
                  className={!nameValid ? "invalid" : ""}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                <label>Name</label>
              </div>
              <div className="form-item">
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className={!emailValid ? "invalid" : ""}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
                <label>Email</label>
              </div>
              <div className="form-item">
                <textarea
                  className=""
                  name="message"
                  id="message"
                  required
                ></textarea>
                <label>Message</label>
              </div>
              <button className="btn submit-btn" type="submit">
                {status}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
