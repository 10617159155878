import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import "./cart.css";
import CartItem from "./CartItem";
import Checkout from "./Checkout";
import formatCurrency from "../../utilities/formatCurrency";
import empty from "../../Images/Backgrounds/empty-cart.png";
import { Helmet } from "react-helmet";

function Cart() {
  const { cartItems, getTotalCartAmount, getCartSize } =
    useContext(CartContext);
  const totalAmount = getTotalCartAmount();
  const totalSize = getCartSize();

  let cart = [];
  let count = 0;

  for (let i = 0; i < cartItems.length; i++) {
    if (cartItems[i].Qty > 0) {
      let offerText = "No Offer"; // To hold the offer text
      let finalPrice = cartItems[i].Price; // Default price

      if (cartItems[i].Sale === 1) {
        finalPrice = cartItems[i].Price * 0.8;
        offerText = "20% discount";
      } else if (cartItems[i].Sale33 === 1) {
        finalPrice = (cartItems[i].Price * 2) / 3;
        offerText = "Buy 2 Get 1";
      } else if (cartItems[i].Sale50 === 1) {
        finalPrice = cartItems[i].Price * 0.5;
        offerText = "50% discount";
      }

      cart[count] = {
        id: count,
        Name: cartItems[i].Name,
        Price: finalPrice,
        Image: cartItems[i].Image,
        Qty: cartItems[i].Qty,
        Offer: offerText,
      };
      count++;
    }
  }

  return (
    <div className="cart-body">
      <Helmet>
        <title>My Cart</title>
      </Helmet>
      <div className="cart-card">
        {totalSize === 0 ? (
          <div className="empty-cart">
            <img src={empty} alt="emptyCart" loading="lazy" />
            <div className="empty-cart-message">
              <h2>Your Cart is Empty</h2>
              <p>Looks like you haven't added anything to your cart yet</p>
              <button className="btn btn-lg action-btn">
                <Link to={"/"}>Go to Home</Link>
              </button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="cart-items">
              <div className="heading">
                <h1>My Cart</h1>
                <p>({totalSize}) items in cart.</p>
              </div>
              <div className="cart-table">
                <div className="item-cart">
                  {cart.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        <CartItem item={item} />
                      </React.Fragment>
                    ),
                    []
                  )}
                </div>
              </div>
              <div className="cart-subtotal">
                Subtotal:
                <span>{formatCurrency(totalAmount)}</span>
              </div>
            </div>
            <div className="cart-checkout">
              <Checkout cart={cart} />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Cart;
