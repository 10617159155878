import React, { useState } from "react";
import { RecipeCategories } from "../../data/RecipeCategories";
import Hero from "../Home/Hero";


function RecipesHero({getRecipeFilter}) {
  const [recipeFilter, setRecipeFilter] = useState("All");
  const handleRecipeFilter = (value) => {
    setRecipeFilter(value); // set filter tag
    getRecipeFilter(value);
  };
  return (
    <div>
      <Hero
        title={"Recipes"}
        subtitle={
          "Choose your recipe for a 20% discount on the ingredients!"
        }
        heroClass={"Recipe"}
      />

      <div className="filter-tab-recipe">
        <ul>
          {RecipeCategories.map(
            (category, index) => (
              <li
                className={
                  recipeFilter === category.catShort
                    ? "filter-recipe active"
                    : "filter-recipe"
                }
                onClick={() => handleRecipeFilter(category.catShort)}
                key={index}
              >
                {category.catName}
              </li>
            ),
            []
          )}
        </ul>
      </div>
    </div>
  );
}
export default RecipesHero;
